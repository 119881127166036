import createFormHelper from '../../../../form/helpers/form_helper'
import { clearBadgesAndUpdateParamsFor } from '../../../../form/input/multi_select_search/component';

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.js-rms_pet_form')
  if (!el) return
  const petForm = createFormHelper(el)
  let previousSpeciesValue = null

  const initialFormState = petForm.getState()
  previousSpeciesValue = initialFormState['rms_pet_assignment[pet_attributes][species_id]'].value
  handleSpeciesChange(initialFormState, true)
  handleSeniorField(initialFormState)

  petForm.onChange(formState => {
    handleSpeciesChangeIfNecessary(formState)
    handleSeniorField(formState)
  })

  function handleSpeciesChangeIfNecessary(formState) {
    const newSpecies = formState['rms_pet_assignment[pet_attributes][species_id]'].value
    if (newSpecies !== previousSpeciesValue) {
      handleSpeciesChange(formState, false)
      previousSpeciesValue = newSpecies
    }
  }

  function handleSeniorField(formState) {
    const birthdate = new Date(formState['rms_pet_assignment[pet_attributes][birthdate]'].value)
    const minSeniorAge = formState['rms_pet_assignment[pet_attributes][senior]'].dataAttr.minAge
    const currentDate = new Date()
    const timeDiff = currentDate.getTime() - birthdate.getTime()
    const petAge = Math.floor(timeDiff / (1000 * 3600 * 24 * 365.25))

    if (petAge >= minSeniorAge) {
      petForm.show('rms_pet_assignment[pet_attributes][senior]')
    } else {
      petForm.hide('rms_pet_assignment[pet_attributes][senior]')
    }
  }

  function handleSpeciesChange(formState, skipBadgeReset) {
    const species = formState['rms_pet_assignment[pet_attributes][species_id]'].value
    const breedsInputName = 'rms_pet_assignment[pet_attributes][breeds]'
    const cFormSelectSearch = document.querySelector(`[name="${breedsInputName}"]`).closest('.js-c-form-multi-select-search')

    // Reset all fields. 
    // If you add new fields to be dynamically changed below in the switch/case then add them here too
    petForm.reset([
      'rms_pet_assignment[pet_attributes][breeds]',
      'rms_pet_assignment[pet_attributes][mix]',
      'rms_pet_assignment[pet_attributes][size]',
      'rms_pet_assignment[pet_attributes][vaccinated]',
      'rms_pet_assignment[pet_attributes][heart_worm_treated]',
      'rms_pet_assignment[pet_attributes][wormed]',
      'rms_pet_assignment[needs_constant_care]',
      'rms_pet_assignment[pet_attributes][coat]',
      'rms_pet_assignment[indoor_only]'
    ])

    // Clear hidden input for breeds
    const breedsInput = document.querySelector(`input[name="${breedsInputName}"]`)
    if (breedsInput) breedsInput.value = ''

    switch (species) {
      case '1': // Dog
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][size]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        petForm.show('rms_pet_assignment[pet_attributes][heart_worm_treated]')
        petForm.show('rms_pet_assignment[pet_attributes][wormed]')
        petForm.show('rms_pet_assignment[needs_constant_care]')
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 1 }))
        }
        break
      case '2': // Cat
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][coat]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        petForm.show('rms_pet_assignment[pet_attributes][wormed]')
        petForm.show('rms_pet_assignment[indoor_only]')
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 2 }))
        }
        break
      case '3': // Guinea Pig
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][coat]')
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 3 }))
        }
        break
      case '4': // Rabbit
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 4 }))
        }
        break
      default:
        break
    }

    if (!skipBadgeReset) {
      clearBadgesAndUpdateParamsFor(breedsInput, { species_id: species })
    }
  }
});