export function clearBadgesAndUpdateParamsFor(inputElement, newParams = {}) {
  if (!inputElement) return
  const multiSelectEl = inputElement.closest('.js-c-form-multi-select-search')
  if (!multiSelectEl) return

  const badgesContainer = multiSelectEl.querySelector('.c-badges-container')
  const multiHiddenInput = multiSelectEl.querySelector('.js-c-form-multi-select-search-input-hidden')
  const selectSearchEl = multiSelectEl.querySelector('.c-form-select-search')

  // Clear the badges and reset the hidden input
  if (badgesContainer) badgesContainer.innerHTML = ''
  if (multiHiddenInput) multiHiddenInput.value = '[]'

  // Update the query params with the new or other params passed
  if (selectSearchEl) {
    const currentParams = JSON.parse(selectSearchEl.dataset.queryParams || '{}')
    const updatedParams = { ...currentParams, ...newParams, ts: Date.now() } // Adding a timestamp to force reload
    selectSearchEl.dataset.queryParams = JSON.stringify(updatedParams)

    // Trigger a reload event for the select_search component
    selectSearchEl.dispatchEvent(new Event('reload', { bubbles: true }))
  }
}

document.querySelectorAll('.js-c-form-multi-select-search').forEach(multiSelectEl => {
  const multiHiddenInput = multiSelectEl.querySelector('.js-c-form-multi-select-search-input-hidden')
  const badgesContainer = multiSelectEl.querySelector('.c-badges-container')
  const badgeClasses = badgesContainer.dataset.badgeClasses
  const removeButtonClasses = badgesContainer.dataset.removeButtonClasses
  const singleSelectInput = multiSelectEl.querySelector('.js-c-form-select-search-input-hidden')

  // Create the existing badges
  function initializeBadges() {
    let selectedItems = []
    try {
      selectedItems = JSON.parse(multiHiddenInput.value || '[]')
    } catch (error) {
      console.error('Error parsing multiHiddenInput.value:', error)
      selectedItems = []
    }
    // Clear existing badges
    badgesContainer.innerHTML = ''

    selectedItems.forEach(item => {
      if (typeof item === 'object' && item.value && item.label) {
        addBadge(item.value, item.label)
      } else {
        addBadge(item, item)
      }
    })
  }

  // Creates a badge for (value, label), unless it's already present
  function addBadge(value, label) {
    if (!value) return

    // If we already have this value, do nothing
    if (badgesContainer.querySelector(`[data-value="${value}"]`)) return

    const badge = document.createElement('span')
    const badgeId = `badge-${value}`
    badge.className = badgeClasses
    badge.textContent = label
    badge.id = badgeId
    
    const svgIcon = document.createElement('img')
    svgIcon.src = 'https://unpkg.com/lucide-static@0.471.0/icons/x.svg'
    svgIcon.width = 16
    svgIcon.height = 16

    const removeBtn = document.createElement('button')
    removeBtn.className = `js-c-form-multi-select-search-remove ${removeButtonClasses}`
    removeBtn.type = 'button'
    removeBtn.dataset.value = value
    removeBtn.appendChild(svgIcon);
    removeBtn.setAttribute('aria-describedby', badgeId);
    removeBtn.setAttribute('aria-label', `Remove ${label}`)
    removeBtn.addEventListener('click', () => {
      badge.remove()
      updateMultiHiddenInput()
    })

    badge.appendChild(removeBtn)
    badgesContainer.appendChild(badge)
    updateMultiHiddenInput()
  }

  // Get all the current badges and build a JSON out of it and store in multiHiddenInput
  function updateMultiHiddenInput() {
    const values = Array.from(
      badgesContainer.querySelectorAll('.js-c-form-multi-select-search-remove')
    ).map(btn => btn.dataset.value)

    multiHiddenInput.value = JSON.stringify(values)
  }

  // When user picks an item in select_search, we get singleSelectInput.value
  // and singleSelectInput.dataset.text to create the badge
  // we also clear the selectSearch
  singleSelectInput.addEventListener('change', () => {
    const val = singleSelectInput.value
    const text = singleSelectInput.dataset.text
    if (val) addBadge(val, text || val)

    // Clear the singleSelect so user can pick again
    singleSelectInput.value = ''
    singleSelectInput.dataset.text = ''

    // Also clear the visible input in the select_search
    const visibleInput = multiSelectEl.querySelector('.js-c-form-select-search-input')
    if (visibleInput) visibleInput.value = ''
  })

  // Initialize badges on load
  initializeBadges()
})